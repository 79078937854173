.globalContainer {
  padding-bottom: 132px;
  /* padding-top: 92px; */
  background: #f0f2f5;
  min-width: 500px;
  display: flex;
  align-items: center;
  height: 100vh;
}

.flexContainer {
  font-family: inherit;
  display: flex;
  margin: auto;
  width: 980px;
  /* align-items: center; */
}

.leftContainer {
  box-sizing: border-box;
  margin-right: 0;
  padding-right: 32px;
  width: 580px;
}

.leftPad {
  padding: 112px 0 16px;
}

.leftPad img {
  height: 138px;
  /* margin: -28px; */
}
.leftPad h2 {
  font-size: 28px;
  font-weight: normal;
  line-height: 32px;
  width: 500px;
}

.rightContainer {
  font-family: inherit;
  display: inline-block;
  vertical-align: top;
}

.rightDiv {
  font-family: inherit;
  height: 456px;
  width: 396px;
}

.loginForm {
  background-color: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin: 40px 0 0;
  padding: 20px 0 28px;
  width: 396px;
  text-align: center;
}

#formEmail {
  margin: auto;
  width: 330px;
}
#formPassword {
  margin: auto;
  width: 330px;
}

input {
  font-size: 17px;
  padding: 14px 16px;
  width: 330px;
}

.form-control {
  width: 364px;
  margin: auto;
  font-size: 17px;
  padding: 14px 16px;
  width: 330px;
}

.btnLogin {
  color: white;
  background-color: #1877f2;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  line-height: 48px;
  padding: 0 16px;
  width: 332px;
}

.separator {
  align-items: center;
  border-bottom: 1px solid #dadde1;
  display: flex;
  margin: 20px 16px;
  text-align: center;
}

.t-align-center {
  text-align: center;
}
