.home-container {
  width: 100%;
  margin-bottom: 50px;
  background-color: #2ea9e0;
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -10%;
  right: -18%;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  /* color: #4c4c4c; */
  color: #fff;
  text-shadow: 2px 2px BLACK;
  max-width: 600px;
  font-weight: 700 !important;
  margin-bottom: 30px !important;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  /* color: #6a6a6a; */
  color: #fff;
  text-shadow: 1px 1px BLACK;
  text-shadow: -1px -1px BLACK;
  margin: 1.5rem 0rem !important;
  max-width: 495px !important;
  margin-bottom: 40px !important;
  margin-bottom: 30px !important;
}

.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.primary-button:hover {
  background-color: rgb(234, 234, 234);
}

.secondary-button {
  padding: 1rem 2.5rem;
  /* background-color: #fe9e0d; */
  background-color: #dc3545;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #e48f0f;
}

.titleSection {
  margin-top: 20px !important;
  margin-bottom: 40px !important;
  font-weight: 700 !important;
  text-transform: capitalize;
}

.get-discount-single-item {
  z-index: 0 !important;
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-flex: 0 !important;
  -webkit-flex: 0 0 auto !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
  border-radius: 10px !important;
  /* background-color: #f2e4d9 !important; */
  background-color: #f2e4d9 !important;
}

.get-discount-item-wrap {
  display: -ms-grid !important;
  display: grid !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  grid-auto-columns: 1fr !important;
  grid-column-gap: 23px !important;
  grid-row-gap: 16px !important;
  -ms-grid-columns: 1fr 1fr 1fr 1fr !important;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  -ms-grid-rows: auto !important;
  grid-template-rows: auto !important;
}

.tituloDescuento {
  /* max-width: 381px !important; */
  margin-top: 0px !important;
  /* font-family: Inter, sans-serif !important; */
  font-size: 22px !important;
  font-weight: 700 !important;
}

.discount-title {
  max-width: 381px;
  margin-top: 0px;
  margin-bottom: 10px !important;
  font-weight: 700 !important;
  font-size: 22px;
}

.get-discount-content {
  font-weight: 700 !important;
  padding: 30px !important;
}

.text-span-2 {
  position: relative !important;
  top: -16px !important;
  font-size: 31px !important;
  line-height: 41px !important;
  font-weight: 700 !important;
  padding-bottom: 10 !important;
}

.discount-price {
  color: #dc3545 !important;
  font-size: 52px !important;
  line-height: 52px !important;
  font-weight: 700 !important;
}

.discount-paragraph {
  max-width: 385px !important;
  margin-bottom: 0px !important;
  color: #231f1e !important;
  font-size: 16px !important;
  line-height: 160% !important;
  font-weight: 700 !important;
}

.get-discount-thumbnail-wrap {
  position: relative !important;
  overflow: hidden !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.discount-image {
  max-width: 100% !important;
  vertical-align: middle !important;
  display: inline-block !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding: 10px !important;
}

.product-tab-item-wrap {
  display: -ms-grid !important;
  display: grid !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  grid-auto-columns: 1fr !important;
  grid-column-gap: 24px !important;
  grid-row-gap: 16px !important;
  -ms-grid-columns: 1fr 1fr 1fr 1fr !important;
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  -ms-grid-rows: auto !important;
  grid-template-rows: auto !important;
}

.product-tab-link {
  margin-right: 16px !important;
  padding: 14px 23px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #c5c5c5 !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  -webkit-transition: all 200ms ease !important;
  transition: all 200ms ease !important;
  font-family: Eina01, sans-serif !important;
  color: #231f1e !important;
  font-size: 14px !important;
  line-height: 100% !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
}

.product-tabs-menu {
  padding-bottom: 40px !important;
}
.w-tab-menu {
  position: relative !important;
}

.product-tab-link.selected {
  transition: background-color 0.3s ease !important;
  background-color: #2ea9e0 !important;
}

.productoCard {
  /* Other styles for .productoCard */
}

.productoImg {
  transition: transform 0.3s ease;
}

.productoCard:hover .productoImg {
  transform: scale(1.2);
}
